<template>
  <div class="container">
    <div class="pad_le25">
      <div style="display: flex">
        <!-- Left Layer -->
        <esp-dx-data-grid :data-grid="dataGrid" ref="dataGrid" @row-click="onRowClick" @content-ready="onContentReady" />
        <!-- Left Layer -->

        <!-- Right Layer -->
        <div class="pad_ri25">
          <Tabs id="tabs" ref="tabs" :tabType="2">
            <Tab title="스킬 설정">
              <esp-dx-data-grid :data-grid="dataGrid2" ref="dataGrid2" @saving="onSaving" />
            </Tab>
            <Tab title="변경 이력">
              <esp-dx-data-grid :data-grid="dataGrid3" ref="dataGrid3" @cell-hover-changed="onCellHoverChanged" />
              <DxPopover width="300px" :visible="popoverVisible" :target="hoverTarget" position="top" :close-on-outside-click="true">
                <ul>
                  <li v-for="(skl, i) in this.popoverData">
                    <strong>{{ skl.sklNm }} ({{ skl.priority }})</strong><br />
                  </li>
                </ul>
              </DxPopover>
            </Tab>
          </Tabs>
        </div>
        <!-- Right Layer -->
      </div>
    </div>

    <!--
        <div class="page-sub-box sub_ui_box1 pad_top20">
          <div class="table_list fl" style="width: 790px">

          </div>
          <div class="fl">

          </div>
        </div>-->
    <!--
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1 pad_top20">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid
            :data-grid="dataGrid"
            ref="dataGrid"
        >
        </DxDataGrid>
      </div>
    </div>
    -->
    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            :is="modal.currentComponent"
            v-model="modal.modalContent"
            :method="modal.currentSaveType"
            :maxId="modal.maxId"
            :maxOrd="modal.maxOrd"
            :modalContent="modal.modalContent"
          />
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : !modal.initData.buttons.hasOwnProperty('save')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          //type: 'default',
          width: '120',
          height: '40',
          onClick: e => {
            onConfirmModal(e);
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';

  import {
    DxColumn,
    DxRequiredRule,
    DxLookup,
    DxScrolling,
    DxPaging,
    DxPager,
    DxEditing,
    DxSelection,
    DxColumnChooser,
    DxColumnFixing,
    DxSorting,
    DxFilterPanel,
  } from 'devextreme-vue/data-grid';

  import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxScrollView } from 'devextreme-vue/scroll-view';

  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';

  import ModalSklGroup from '@/pages/cc/skl/group/modal-skl-group.vue';

  import { isSuccess, formatDate } from '@/plugins/common-lib';
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import SearchDataGrid from '@/pages/esp/auth/search-data-grid.vue';
  import { DxFilterRow, DxTreeList } from 'devextreme-vue/tree-list';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxPopover } from 'devextreme-vue/popover';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = null;

  export default {
    components: {
      EspDxDataGrid,
      DxPopover,
      DxCheckBox,
      DxFilterRow,
      DxTreeList,
      DxSorting,
      SearchDataGrid,
      Tabs,
      Tab,
      Search,
      DxColumn,
      DxRequiredRule,
      DxLookup,
      DxScrolling,
      DxPaging,
      DxPager,
      DxEditing,
      DxSelection,
      DxColumnChooser,
      DxColumnFixing,
      DxFilterPanel,
      DxPopup,
      DxPosition,
      DxToolbarItem,
      DxScrollView,
      DxTextBox,
      DxButton,
      DxSelectBox,
      ModalSklGroup,
    },
    props: {},
    watch: {},
    data() {
      return {
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          paramsData: null,
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          currentSaveType: 'INSERT',
          maxId: 0,
          maxOrd: 0,
          modalContent: {}, // modal-template 와 데이터를 주고받기위해 사용하는 값
        },
        viewCd: [
          { codeNm: '사용', codeValue: 1 },
          { codeNm: '미사용', codeValue: 2 },
        ],
        priority: [
          { codeNm: '미사용', codeValue: 0 },
          { codeNm: '1', codeValue: 1 },
          { codeNm: '2', codeValue: 2 },
          { codeNm: '3', codeValue: 3 },
          { codeNm: '4', codeValue: 4 },
          { codeNm: '5', codeValue: 5 },
          { codeNm: '6', codeValue: 6 },
          { codeNm: '7', codeValue: 7 },
          { codeNm: '8', codeValue: 8 },
          { codeNm: '9', codeValue: 9 },
          { codeNm: '10', codeValue: 10 },
        ],
        popoverVisible: false,
        popoverData: [],
        hoverTarget: '',
        privClickRow: null,
        dataGrid: {
          refName: 'dataGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          wordWrapEnabled: false, //줄바꿈 허용
          dataSource: [],
          width: '790', // 주석처리시 100%
          height: null, // 주석처리시 100%
          //apiActionNm: {
          //    update: '',
          //    delete: '',
          //    select: ''
          //},
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [], //pageData에서 vm을 찾지 못하여 페이지에서 직접 설정
          },
          customEvent: {
            selectionChanged: false,
            initNewRow: false,
            cellPrepared: false,
            rowPrepared: false,
            contentMenuPreparing: false,
            initialized: false,
            contentReady: true,
            optionChanged: false,
            exporting: false,
            rowInserting: false,
            rowInserted: false,
            rowUpdating: false,
            rowUpdated: false,
            rowRemoved: false,
            rowClick: true,
            saving: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          //scrolling: {                                      // 미사용시 주석처리
          //    mode: 'virtual'                           //스크롤 모드 : ['infinite', 'standard', 'virtual']
          //},
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: true,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            value: [['sklGroupNm', 'contains', 1]],
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사이트',
              dataField: 'site.siteNm',
              width: 130,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '센터',
              dataField: 'tenant.tenantNm',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '스킬그룹명',
              dataField: 'sklGroupNm',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  vm.onOpenModal(
                    'ModalSklGroup',
                    {
                      title: '스킬그룹 수정',
                      buttons: {
                        save: { text: '수정' },
                        cancel: { text: '취소' },
                      },
                      width: '500',
                      height: '350',
                    },
                    'UPDATE',
                    options.data,
                  );
                });
                container.append(aTag);
              },
            },
            {
              caption: '스킬개수',
              dataField: 'sklCnt',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '순서',
              dataField: 'sklGroupOrd',
              width: 80,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용여부',
              dataField: 'viewCd',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              filterValue: 1,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: [],
                displayExpr: 'codeNm',
                valueExpr: 'codeValue',
                disabled: true,
              },
            },
          ],
        },
        dataGrid2: {
          refName: 'dataGrid2',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width: '720',                                     // 주석처리시 100%
          height: null, // 주석처리시 100%
          //apiActionNm: {
          //    update: '',
          //    delete: '',
          //    select: ''
          //},
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [], //pageData에서 vm을 찾지 못하여 페이지에서 직접 설정
          },
          customEvent: {
            selectionChanged: false,
            initNewRow: false,
            cellPrepared: false,
            rowPrepared: false,
            contentReady: false,
            optionChanged: false,
            exporting: false,
            rowInserting: false,
            rowInserted: false,
            rowUpdating: false,
            rowUpdated: false,
            rowRemoved: false,
            saving: true,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          //scrolling: {                                      // 미사용시 주석처리
          //    mode: 'virtual'                           //스크롤 모드 : ['infinite', 'standard', 'virtual']
          //},
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '스킬',
              dataField: 'skl',
              width: '50%',
              height: 40,
              alignment: 'left', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '스킬명',
              dataField: 'sklNm',
              width: '50%',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '우선순위',
              dataField: 'priority',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              disable: false,
              lookup: {
                // dataSource: this.$_getCode('priority'),
                dataSource: [],
                displayExpr: 'codeNm',
                valueExpr: 'codeValue',
                disabled: true,
              },
            },
            {
              dataField: 'id',
              visible: false,
            },
          ],
        },
        dataGrid3: {
          refName: 'dataGrid3',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          wordWrapEnabled: true,
          dataSource: [],
          // width: '720',                                     // 주석처리시 100%
          height: null, // 주석처리시 100%
          //apiActionNm: {
          //    update: '',
          //    delete: '',
          //    select: ''
          //},
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [], //pageData에서 vm을 찾지 못하여 페이지에서 직접 설정
          },
          customEvent: {
            selectionChanged: false,
            initNewRow: false,
            cellPrepared: false,
            rowPrepared: false,
            contentReady: false,
            optionChanged: false,
            exporting: false,
            rowInserting: false,
            rowInserted: false,
            rowUpdating: false,
            rowUpdated: false,
            rowRemoved: false,
            saving: false,
            cellHoverChanged: true,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'none', // single multiple
          },
          //scrolling: {                                      // 미사용시 주석처리
          //    mode: 'virtual'                           //스크롤 모드 : ['infinite', 'standard', 'virtual']
          //},
          remoteOperations: {
            // 서버사이드 페이징 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '스킬',
              dataField: 'sklList',
              // width: '50%',
              height: 40,
              alignment: 'left', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.formatSklList,
            },
            {
              caption: '수정자',
              dataField: 'regId',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일시',
              dataField: 'eventDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatEventDt,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 행 클릭시 이벤트
       * 클릭된 row class 변경 및 Left Grid 처리 */
      onRowClick(row) {
        vm = this;
        let clickRowElement = row.rowElement;
        clickRowElement.key = row.key;
        clickRowElement.classList.add('dx-selection-custom');
        if (this.privClickRow && clickRowElement.key !== this.privClickRow.key) {
          this.privClickRow.classList.remove('dx-selection-custom');
        }

        this.privClickRow = clickRowElement;

        console.log(row.data);

        this.dataGrid2.dataSource = row.data.sklList;
        // this.dataGrid3.dataSource = row.data.sklGroupHistory;
        this.dataGrid3.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = '-eventDt';

            params.sklGroupId = row.key;

            params = { ...params, ...vm.searchType.paramsData };

            let payload = {
              actionname: 'SKL_GROUP_HISTORY_LIST_ALL',
              //data:{params:data},
              data: { params: params },
              loading: false,
              useErrorPopup: true,
            };

            let res = await vm.CALL_API(payload);

            if (isSuccess(res)) {
              let data = {};
              res.data.data?.forEach((item, index) => {
                const historyId = item.historyId;
                if (data[historyId]) {
                  data[historyId].sklList.push({ sklNm: item.sklNm, priority: item.priority });
                } else {
                  data[historyId] = {
                    id: item.historyId,
                    regId: item.regId,
                    eventDt: item.eventDt,
                    sklList: [{ sklNm: item.sklNm, priority: item.priority }],
                  };
                }
                item.id = index;
              });

              const array = Object.values(data);

              let rtnData = {
                data: array,
                totalCount: array.length,
              };

              vm.$refs.dataGrid3.totalCount = rtnData.totalCount;

              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      /** @description: reordering OR filtering 시 클릭된 row class 처리 */
      onContentReady(e) {
        if (this.privClickRow) {
          if (e.component.getRowIndexByKey(this.privClickRow.key) !== -1) {
            let clickRowElement = e.component.getRowElement(e.component.getRowIndexByKey(this.privClickRow.key))[0];
            clickRowElement.key = this.privClickRow.key;
            clickRowElement.classList.add('dx-selection-custom');
            this.privClickRow = clickRowElement;
          }
        }
      },
      /** @description: 행 mouseover 이벤트 정의. 스킬리스트 표출을 위해 사용*/
      onCellHoverChanged(e) {
        this.popoverVisible = false;
        if (e.rowType == 'data' && e.columnIndex === 0) {
          if (e.eventType === 'mouseover') {
            this.hoverTarget = e.row.cells[0].cellElement;
            this.popoverData = e.row.data.sklList.filter(skl => skl.priority !== 0);
            this.popoverVisible = true;
          }
        }
      },
      /** @description: 스킬리스트 포멧 */
      formatSklList(rowData) {
        let sklList = '';
        rowData.sklList?.forEach(skl => {
          sklList += `${skl.sklNm}(${skl.priority}), `;
        });
        sklList = sklList.slice(0, sklList.length - 2);
        return sklList;
      },
      /** @description: 날짜 포멧 */
      formatEventDt(rowData) {
        return rowData.eventDt ? formatDate(rowData.eventDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss') : '-';
      },
      /** @description: 모달창이 close될 때, modal 객체 설정 값 초기화 */
      setInitModal() {
        this.modal.isOpened = false;
        this.modal.currentComponent = null;
        this.modal.initData = {};
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 팝업 오픈시 메서드 */
      onOpenModal(componentNm, componentInitData, saveType, data) {
        this.$log.debug(componentNm, componentInitData);
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.currentSaveType = saveType; // 현재 모달이 Insert 목적인지, Update 목적인지에 따라, 호출하는 API를 동적으로 사용하기 위해 사용
        this.modal.modalContent = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 확인 버튼 클릭 메서드 */
      async onConfirmModal(e) {
        //click modal save btn : set saveData get modal body slot componet data
        let promise = new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
        });

        promise
          .then(res => {
            // use res
            if (res === 200) {
              this.selectDataList();
              this.setInitModal();
            } else {
              this.$_Toast('작업 실패');
            }
          })
          .catch(err => {
            // handle error
            this.$_Toast('저장 작업 중 오류가 발생했습니다.');
          });
      },
      /** @description: 날짜 포멧 */
      getFormatDate(date) {
        let year = date.getFullYear(); //yyyy
        let month = 1 + date.getMonth(); //M
        month = month >= 10 ? month : '0' + month; //month 두자리로 저장
        let day = date.getDate(); //d
        day = day >= 10 ? day : '0' + day; //day 두자리로 저장
        return year + '' + month + '' + day; //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
      },
      /** @description: grid refresh 이벤트 */
      refreshDataList() {
        this.$refs.dataGrid.refreshData();
      },
      /** @description: 스킬설정 저장 로직 */
      async onSaving(e) {
        let data = [];
        e.changes.forEach(change => {
          let sklObj = {
            sklId: change.key.id,
            sklGroupId: this.privClickRow.key,
            priority: change.data.priority,
          };
          data.push(sklObj);
        });

        let payload = {
          actionname: 'SKL_GROUP_SKL_LIST_INSERT',
          data: { data: data },
          loading: true,
          useErrorPopup: true,
        };

        let res = await vm.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.refreshDataList();
        }
      },
      /** @description : 스킬그룹 리스트 조회 */
      selectDataList() {
        const vm = this;

        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            vm.$log.debug(loadOptions);
            let params = vm.$_getDxDataGridParam(loadOptions);

            let payload = {
              actionname: 'SKL_GROUP_LIST_ALL',
              data: { params: params },
              loading: true,
              useErrorPopup: true,
            };

            let res = await vm.CALL_API(payload);

            if (isSuccess(res)) {
              let skl_list_payload = {
                actionname: 'CC_SKL_LIST',
                data: { params: { sort: '+sklOrd' } },
                loading: false,
                useErrorPopup: true,
              };
              let skl_list_res = await vm.CALL_API(skl_list_payload);

              let mapped_skl_list_payload = {
                actionname: 'SKL_GROUP_SKL_LIST_ALL',
                loading: false,
                useErrorPopup: true,
              };
              let mapped_skl_list_res = await vm.CALL_API(mapped_skl_list_payload);

              vm.modal.maxId = 0;
              vm.modal.maxOrd = 0;

              let sklList = [];
              skl_list_res.data.data.forEach(skl => {
                let sklObj = {
                  id: skl.id,
                  skl: skl.skl,
                  sklNm: skl.sklNm,
                  priority: 0,
                };
                sklList.push(sklObj);
              });

              res.data.data.forEach(sklGroup => {
                sklGroup.sklList = vm.$_commonlib.cloneObj(sklList);

                let count = 0;
                mapped_skl_list_res.data.data.forEach(mappedSkl => {
                  if (mappedSkl.sklGroupId === sklGroup.id) {
                    sklGroup.sklList.find(skl => {
                      if (skl.id === mappedSkl.sklId && mappedSkl.priority !== 0) {
                        skl.priority = mappedSkl.priority;
                        count++;
                      }
                    });
                  }
                });

                if (sklGroup.id > vm.modal.maxId) {
                  vm.modal.maxId = sklGroup.id;
                }
                if (sklGroup.sklGroupOrd > vm.modal.maxOrd) {
                  vm.modal.maxOrd = sklGroup.sklGroupOrd;
                }

                sklGroup.sklCnt = count;
              });

              let rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              console.log(rtnData);

              vm.$refs.dataGrid.totalCount = rtnData.totalCount;

              return rtnData;
            } else {
              //return () => { throw 'Data Loading Error'; }
            }
          },
        });
      },
      /** @description : 스킬그룹 데이터 삭제 */
      async onDeleteData() {
        let deletedIds = this.$refs.dataGrid.selectedRowsData.map(d => {
          return { id: d.id };
        }); //삭제할 데이터
        let contents = '스킬그룹을 삭제하시겠습니까?(기존 상담원에게 할당된 정보는 삭제되지 않습니다)';

        if (deletedIds.length === 0) {
          this.$_Msg('대상이 선택되어 있지 않습니다.');
          return false;
        }

        if (await this.$_Confirm(contents)) {
          let payload = {
            actionname: 'SKL_GROUP_LIST_DELETE',
            data: { data: { data: deletedIds } },
            loading: true,
          };

          let res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
            this.refreshDataList();
          }
        } else {
          return false;
        }
      },
      /** @description: 그리드 상단 커스텀 버튼 설정(pageData에서 onClick 이벤트의 vm을 찾지 못하여 여기서 설정)  */
      setGridCustomButtons() {
        let customButtons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '스킬그룹 추가',
              elementAttr: { class: 'btn_XS default filled add1' },
              width: 120,
              height: 30,
              onClick() {
                vm.onOpenModal(
                  'ModalSklGroup',
                  {
                    title: '스킬그룹 등록',
                    buttons: {
                      save: { text: '등록' },
                      cancel: { text: '취소' },
                    },
                    width: '500',
                    height: '350',
                  },
                  'INSERT',
                );
              },
            },
            location: 'before',
          },
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '삭제',
              elementAttr: {
                class: 'dx-button dx-button-normal dx-button-mode-text dx-widget btn_XS white light_filled trash dx-button-has-text',
              },
              width: 60,
              height: 30,
              onClick() {
                vm.onDeleteData();
              },
            },
            location: 'before',
          },
        ];
        this.dataGrid.showActionButtons.customButtons = customButtons;
      },
      /** @description: grid 높이 조정 */
      setGridHeight() {
        this.dataGrid.height = window.innerHeight - 148;
        this.dataGrid2.height = window.innerHeight - 210;
        this.dataGrid3.height = window.innerHeight - 210;
      },
      /** @description : 라이프사이클 created 호출되는 메서드 */
      createdData() {
        vm = this;
        this.setGridCustomButtons();
        this.selectDataList();
      },
      /** @description : 라이프사이클 mounted 호출되는 메서드 */
      mountedData() {
        this.dataGrid.columns[5].lookup.dataSource = this.viewCd;
        this.dataGrid2.columns[2].lookup.dataSource = this.priority;

        this.setGridHeight();
        window.addEventListener('resize', this.setGridHeight);
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
    updated() {},
    destroyed() {},
  };
</script>

<style lang="scss" scoped>
  .ecs-sub-tab {
    margin-bottom: 10px;
  }

  .pad_ri25 {
    padding-right: 25px !important;
  }

  ::v-deep {
    .page-sub-box {
      padding: 0 0 0 40px !important;
    }
    .dx-selection-custom > td {
      background-color: #fafafa !important;
    }
    .ecs-tab-box {
      margin-left: 20px !important;
    }
    .dx-freespace-row > td {
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
</style>